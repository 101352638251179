export const environment = {
  firebase: {
    apiKey: "AIzaSyC4DcDOW6iM2-UXkKvRie248h6kkWI5uls",
    authDomain: "wibo-hosting-v4.firebaseapp.com",
    databaseURL: "https://wibo-hosting-v4realtime-cloud.firebaseio.com/",
    projectId: "wibo-hosting-v4",
    storageBucket: "wibo-hosting-v4.appspot.com",
    messagingSenderId: "700523303972",
    appId: "1:700523303972:web:1c4ddb6f66c66a666ab4b1"
  },
  production: false,
  api_url: 'https://bubba-staging.wibodev.com',
  // api_url: 'http://localhost:8000',
  auth_token_key: 'authce9d77b308c149d5992a80073637e4d5',
  site_id: '', //Este campo debe ser generado cada vez que se cree una pwa
  version: '2.2.0',
  support: {
    type: 'chat',
    url: 'https://ayuda.holadary.com/'
  },
  urls_sources: {
    CL:{
        terms_and_conditions: 'https://wibo-flutter-mobile-user.web.app/TrminosycondicionesusuarioChile.html',
        privacy_policy: 'https://wibo-flutter-mobile-user.web.app/TrminosycondicionesusuarioChile.html'
    },
    PE:{
        terms_and_conditions: 'https://docs.google.com/document/d/1Poo6UqzNzxU-Z4dNoU3MHmIe-JZj3zY_LGY3Q0WL4SU/edit?usp=sharing',
        privacy_policy: 'https://docs.google.com/document/d/1Poo6UqzNzxU-Z4dNoU3MHmIe-JZj3zY_LGY3Q0WL4SU/edit?usp=sharing'
    }
  }
};
